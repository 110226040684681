<template>
  <div class="waiting-animation">
    <span class="waiting-animation__item">.</span>
    <span class="waiting-animation__item">.</span>
    <span class="waiting-animation__item">.</span>
  </div>
</template>

<script>
export default {
  name: "Ellipsis"
}
</script>

<style lang="scss">
.waiting-animation {
  display: inline-block;
  width: 0;
  margin-left: -4px;
  &__item {
    animation-name: waiting-animation;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  &__item:nth-child(2) {
    animation-delay: 0.2s;
  }
  &__item:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes waiting-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
